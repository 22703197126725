import React, { useEffect } from "react";
import "./Training.css";
// import Card from "../../shared/components/Card/Card";
import { packages } from "../../shared/services/services";
const Training = () => {
  const textStyles = {
    textAlign: "justify",
    lineHeight: "1.5rem",
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="package" id="package">
        <div className="container">
          <p className="section-subtitle">Training</p>

          <h2 className="h2 section-title">
            Shaping the Future of Tourism Industry
          </h2>

          <p className="section-text w-80 text-justify">
            The travel and tourism business engages around 200 million people
            globally. The tourism industry is expected to flourish, presenting
            both potential and challenges.
          </p>
    
          <p className="section-text w-80 p20 text-justify">
            The potential is to engage in the tourism industry and create
            high-quality opportunities. And the problem is that to fuel a
            planned development and accomplish tourism sector efficiency and
            viability, the relevant tourism human capital base is needed. Which
            satisfies current and future market expectations and, as a result,
            improves tourism destinations' profitability and stability. This is
            where we step in. Our travel and tourism training is tailored to
            equip you with the knowledge and competencies needed to succeed in
            this fast-growing profession.
          </p>

          {/* Training Packages */}

          <ul className="package-list">
            {packages.map((pkg, index) => (
              <li key={index}>
                <div className="package-card">
                  <figure className="card-banner">
                    <img src={pkg.image} alt={pkg.alt} loading="lazy" />
                  </figure>

                  <div className="card-content">
                    <h3 className="h3 card-title">{pkg.title}</h3>

                    {pkg.description && (
                      <p className="card-text">{pkg.description}</p>
                    )}

                    {pkg.list && (
                      <ul>
                        {pkg.list.map((item, i) => (
                          <li key={i}>
                            {item.label ? <strong>{item.label}:</strong> : null}{" "}
                            {item.value}
                          </li>
                        ))}
                      </ul>
                    )}

                    {/* {pkg.additionalText && <p>{pkg.additionalText}</p>}
                     */}
                    {pkg.additionalText && (
                      <div
                        className="additional-text list-group-item"
                        style={textStyles}
                        dangerouslySetInnerHTML={{ __html: pkg.additionalText }}
                      />
                    )}
                  </div>
                </div>
              </li>
            ))}
          </ul>

          {/* ----------------------------------- */}
        </div>
      </section>
    </>
  );
};
export default Training;
