import React from "react";
import "./Services.css";
import { Outlet } from "react-router-dom";
import "../../App.css";
export default function Services() {
  return (
    <>
      <Outlet />
    </>
  );
}
