import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./CallToAction.css";

const CallToAction = () => {
  const navigate = useNavigate();
  const location = useLocation();

  if (location.pathname === "/contact") {
    return null;
  }
  return (
    <>
      <section className="cta" id="contact">
        <div className="container">
          <div className="cta-content">
            <p className="section-subtitle">Call To Action</p>

            <h2 className="h2 section-title call-to-action-custom">
            Ready For the employees and team capcity building and training & Unforgettable Travel. Remember Us!
            </h2>

            <p className="section-text">
              Boost your travel business with our expert tourism consultancy. We
              offer tailored solutions in market analysis, destination
              management, sustainable tourism, and digital marketing. Whether
              new or established, we help you create memorable experiences,
              streamline operations, and elevate customer satisfaction for
              lasting success.
            </p>
            <p className="cta-highlight flex">
              <span>
                {" "}
                📈 Transform your vision into a thriving tourism enterprise.
              </span>
              <span>
                🌍 Partner with us for success in the ever-evolving travel
                industry.
              </span>
            </p>
          </div>

          <button
            className="btn btn-secondary"
            onClick={() => {
              navigate("/contact");
            }}
          >
            Contact Us!
          </button>
        </div>
      </section>
    </>
  );
};
export default CallToAction;
