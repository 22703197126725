import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import About from "./components/About";
import Banner from "./components/Banner";
import Contact from "./components/Contact";
import Gallery from "./components/Gallery";
import Header from "./components/Header";
import Services from "./components/Services";

// Services Categories (assuming these are separate components)
import DigitalBusiness from "./pages/DigitalBusiness";
import SocialMedia from "./pages/SocialMedia";
import TourismConsultation from "./pages/TourismConsultation";
import TourismMarketing from "./pages/TourismMarketing";
import TourismResearch from "./pages/TourismResearch";
import Training from "./pages/Training";

// Footer
import { Suspense, lazy } from "react";
import { Helmet } from "react-helmet";
import "./App.css";
import CallToAction from "./components/CallToAction";
import Footer from "./components/Footer";
import Tourism from "./pages/Tourism";
import TourismEducation from "./pages/TourismEducation/TourismEducation";
import { ImageProvider } from "./shared/services/imageContext/imageContext";

const LazyLoadedOurTeam = lazy(() => import("./components/OurTeam"));
const LazyLoadedGallery = lazy(() => import("./components/Gallery"));

function App() {
  const location = useLocation();

  return (
    <div className="App">
      <Helmet>
        <title>Journey to Explore World - NAFA Tourism Consultancy</title>
        <meta
          name="description"
          content="NAFA - Tourism Consultancy: India’s leading specialist in travel and tourism marketing, training, and consultancy. Providing expert guidance in tourism, leisure, and hospitality."
        />
        <meta
          name="keywords"
          content="tourism consultancy, travel marketing, tourism training, hospitality consultancy, India tourism, NAFA"
        />
        <meta
          property="og:title"
          content="Journey to Explore World - NAFA Tourism Consultancy"
        />
        <meta
          property="og:description"
          content="NAFA - Tourism Consultancy: India’s leading specialist in travel and tourism marketing, training, and consultancy."
        />
        <meta property="og:url" content="https://www.nafatourism.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="/assets/images/nafa_logo.png" />
      </Helmet>

      <Header />
      <Banner />
      <ImageProvider>
        <Routes>
          <Route path="services" element={<Services />}>
            {/* Services Nested Routes */}
            <Route path="/services/training" element={<Training />} />
            <Route
              path="/services/tourism-research"
              element={<TourismResearch />}
            />
            <Route
              path="/services/digital-business"
              element={<DigitalBusiness />}
            />
            <Route path="/services/social-media" element={<SocialMedia />} />
            <Route
              path="/services/tourism-consultation"
              element={<TourismConsultation />}
            />
            <Route
              path="/services/tourism-marketing"
              element={<TourismMarketing />}
            />
            <Route path="/services/tourism" element={<Tourism />} />
          </Route>

          <Route path="gallery" element={<Gallery />} />
          <Route path="about" element={<About />} />
          <Route path="contact" element={<Contact />} />
          <Route path="news" element={<TourismEducation />} />

          <Route path="*" element={<Navigate to="/" replace={true} />} />
        </Routes>

        {location.pathname === "/" && (
          <Suspense fallback={<div>Loading...</div>}>
            <LazyLoadedGallery />
            <LazyLoadedOurTeam />
          </Suspense>
        )}
      </ImageProvider>

      <CallToAction />
      <Footer />
    </div>
  );
}

export default App;
