import ExpandMoreIcon from "@mui/icons-material/ExpandMore"; // For the expand icon
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  Typography
} from "@mui/material";
import React, { useEffect } from "react";
import { pageData } from "../../shared/services/services";
import "./TourismEducation.css";



const TourismEducation = () => {
  const pageContent = pageData;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tourism-education-container">
      {/* Header Section */}
      <header className="tourism-education-header">
        <Typography
          variant="h4"
          gutterBottom
          sx={{
            fontWeight: 600,
            textAlign: "center",
            fontSize: { xs: "1.5rem", sm: "2.5rem" },
          }}
          className="h2 section-title"
        >
          {pageContent.title}
        </Typography>
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            lineHeight: 1.6,
            textAlign: "justify",
            fontSize: { xs: "0.9rem", sm: "1rem" },
          }}
          className="text-justify"
        >
          {pageContent.introduction}
        </Typography>
      </header>

      {/* Main Content Loop */}
      {pageContent.sections.map((section, index) => (
        <section key={index} className="section">
          <Typography
            variant="h5"
            gutterBottom
            sx={{
              color: "#34495E",
              fontWeight: 700,
              marginBottom: "15px",
              fontSize: { xs: "1.3rem", sm: "1.7rem" },
              textAlign: "center",
            }}
          >
            {section.title}
          </Typography>

          {/* Accordion for each piece of content */}
          {section.content.map((content, subIndex) => (
            <Accordion key={subIndex} sx={{ marginBottom: "15px" }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls={`panel${subIndex}-content`}
                id={`panel${subIndex}-header`}
              >
                <Typography variant="h6" sx={{ fontWeight: 600 }}>
                  {content.subtitle}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {/* Render weeks for "IATA Travel and Tourism Training Course Syllabus" */}
                {content.weeks ? (
                  <Box sx={{ marginBottom: "10px" }}>
                    {content.weeks.map((week, weekIndex) => (
                      <div key={weekIndex}>
                        <Typography variant="body1" sx={{ fontWeight: 600 }}>
                          Week {week.week}:
                        </Typography>
                        <ul style={{ paddingLeft: "20px" }}>
                          {week.details && <li>{week.details}</li>}
                          {week.details2 && <li>{week.details2}</li>}
                          {week.details3 && <li>{week.details3}</li>}
                        </ul>
                      </div>
                    ))}
                  </Box>
                ) : (
                  // Default content rendering when there are no weeks
                  <Box sx={{ marginBottom: "10px" }}>
                    <ul style={{ paddingLeft: "20px" }}>
                      {content.details && <li>{content.details}</li>}
                      {content.details2 && <li>{content.details2}</li>}
                      {content.details3 && <li>{content.details3}</li>}
                      {content.details4 && <li>{content.details4}</li>}
                    </ul>
                  </Box>
                )}

                {/* Chips (Tags) for additional context or labels */}
                <Box
                  sx={{
                    display: "flex",
                    gap: "8px",
                    marginTop: "10px",
                    paddingLeft: "20px",
                  }}
                >
                  {content.details && (
                    <Chip
                      label="Key Point"
                      color="primary"
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  )}
                  {content.details2 && (
                    <Chip
                      label="Insight"
                      color="secondary"
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  )}
                  {content.details3 && (
                    <Chip
                      label="Fact"
                      color="default"
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  )}
                  {content.details4 && (
                    <Chip
                      label="Important"
                      color="error"
                      size="small"
                      sx={{ fontWeight: 500 }}
                    />
                  )}
                </Box>
              </AccordionDetails>
            </Accordion>
          ))}
        </section>
      ))}

      {/* Footer with Conclusion */}
      <footer
        className="tourism-footer"
        style={{ marginTop: "40px", padding: "20px" }}
      >
        <Typography
          variant="body1"
          color="textSecondary"
          sx={{
            lineHeight: 1.6,
            fontSize: { xs: "0.9rem", sm: "1rem" },
            textAlign: "center",
          }}
        >
          {pageContent.conclusion}
        </Typography>
      </footer>
    </div>
  );
};

export default TourismEducation;
