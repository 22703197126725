import React, { useState } from "react";
import {
  IoCallOutline,
  IoMailOutline,
  IoLocationOutline,
  IoChevronUpCircle,
} from "react-icons/io5";
import "./Footer.css";
import "../../App.css";
import PrivacyPolicyModal from "../../shared/PrivacyPolicyModal/PrivacyPolicyModal";

const Footer = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  // Open the Privacy Policy Modal
  const handleOpenModal = () => {
    setModalOpen(true);
  };

  // Close the Privacy Policy Modal
  const handleCloseModal = () => {
    setModalOpen(false);
  };

  // Get the current year dynamically
  const currentYear = new Date().getFullYear();

  // Handle Contact button clicks
  const handleContactClick = (type) => {
    if (type === "call") {
      window.location.href = "tel:+919619982990";
    } else if (type === "mail") {
      window.location.href = "mailto: shaikh@nafatourism.com";
    } else if (type === "location") {
      alert(`Location: 
        A2 - 601, D S K Madhuabn,
        A. K. Road, Near Saki Naka Junction,
        Mumbai-400072, Maharashtra, INDIA`);
    }
  };

  return (
    <>
      <footer className="footer">
        <div className="footer-top">
          <div className="container">
            <div className="footer-brand">
              <div className="logo">
                <img src="/assets/images/nafa_logo.png" alt="Nafa Logo" />
              </div>
              <p className="footer-text t-align-justify">
                <strong>NAFA - Tourism Consultancy</strong>
                <br />
                <strong>
                  <i>"There is no Business like Travel Business”</i>
                </strong>
                <br />
                NAFA - Tourism Consultancy is India`s leading specialist in
                travel and tourism marketing, training, and consultancy. With a
                team of seasoned industry experts and communications
                professionals, we provide comprehensive guidance in tourism,
                leisure, and hospitality.
              </p>
            </div>

            <div className="footer-contact">
              <h4 className="contact-title">Contact Us</h4>
              <p className="contact-text">
                Feel free to contact and reach us !!
              </p>
              <ul>
                <li className="contact-item">
                  <IoCallOutline />
                  <button
                    className="contact-link text-color-white"
                    onClick={() => handleContactClick("call")}
                  >
                    +91 9619 982 990
                  </button>
                </li>
                <li className="contact-item text-color-white">
                  <IoMailOutline />
                  <button
                    className="contact-link text-color-white"
                    onClick={() => handleContactClick("mail")}
                  >
                    shaikh@nafatourism.com
                  </button>
                </li>
                <li className="contact-item">
                  <IoLocationOutline />
                  <button
                    className="contact-link"
                    onClick={() => handleContactClick("location")}
                  >
                    <p className="address text-color-white">
                      A2 - 601, D.S.K Madhuabn, A.K. Road, Near Saki Naka
                      Junction, Mumbai-400072, Maharashtra, INDIA
                    </p>
                  </button>
                </li>
              </ul>
            </div>

            <div className="footer-form">
              <p className="form-text">
                Subscribe to our newsletter for more updates & news !!
              </p>
              <form action="#" className="form-wrapper">
                <input
                  type="email"
                  name="email"
                  className="input-field"
                  placeholder="Enter Your Email"
                  required
                />
                <button type="submit" className="btn btn-secondary">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="copyright-div">
              <p className="copyright j-c-sp-btw">
                Copyright &copy; {currentYear}
                <span>Nafa Tourism Consultancy. All rights reserved</span>
                SEO & Design and Developed By{" "}
                <a className="developed-by"
                  href="https://www.linkedin.com/in/adarsh-mishra11/"
                  target="_blank"
                >
                  Adarsh Mishra
                </a>
              </p>
            </div>
            <ul className="footer-bottom-list">
              <li>
                <button
                  className="footer-bottom-link"
                  onClick={handleOpenModal}
                >
                  Privacy Policy
                </button>
              </li>
              <li>
                <button
                  className="footer-bottom-link"
                  onClick={() => alert("Terms & Conditions")}
                >
                  Terms & Conditions
                </button>
              </li>
              <li>
                <button
                  className="footer-bottom-link"
                  onClick={() => alert("FAQ")}
                >
                  FAQ
                </button>
              </li>
            </ul>
          </div>
        </div>
      </footer>

      <a href="#top" className="go-top" data-go-top>
        <IoChevronUpCircle />
      </a>

      {/* Privacy Policy Modal Component */}
      <PrivacyPolicyModal isOpen={isModalOpen} onClose={handleCloseModal} />
    </>
  );
};

export default Footer;
