// import React, { useEffect, useRef, useState } from "react";
// import {
//   IoCloseOutline,
//   IoLogoWhatsapp,
//   IoMenuOutline,
//   IoSearch
// } from "react-icons/io5";
// import { Link } from "react-router-dom";
// import "../../App.css";
// import { navLinks, socialLinks } from "../../shared/services/services";
// import "./Header.css";

// export default function Header() {
//   const [searchQuery, setSearchQuery] = useState("");
//   const overlayRef = useRef(null);
//   const navbarRef = useRef(null);
//   const navOpenBtnRef = useRef(null);
//   const navCloseBtnRef = useRef(null);
//   const headerRef = useRef(null);
//   const goTopBtnRef = useRef(null);

//   // Dropdown state for individual menus
//   const [openDropdown, setOpenDropdown] = useState(null);

//   const toggleDropdown = (index) => {
//     setOpenDropdown((prev) => (prev === index ? null : index));
//   };

//   const toggleNavbar = () => {
//     navbarRef.current?.classList?.toggle("active");
//     overlayRef.current?.classList?.toggle("active");
//   };

//   const handleClick = () => {
//     // window.location.href = "tel:+919619982990";
//     const phoneNumber = "+919619982990"; // WhatsApp number
//     const message =
//       "Hello, I want to know more information about Nafa Tourism Consultancy !";

//     window.location.href = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
//       message
//     )}`;
//   };

//   // Search Bar
//   const handleSearchSubmit = (e) => {
//     e.preventDefault();
//     if (searchQuery.trim()) {
//       window.open(`https://www.google.com/search?q=${searchQuery}`, "_blank");
//     }
//   }

//   const handleChange = (e) => {
//     setSearchQuery(e.target.value);
//   };

//   useEffect(() => {
//     const handleScroll = () => {
//       if (window.scrollY >= 200) {
//         headerRef.current?.classList?.add("active");
//         goTopBtnRef.current?.classList?.add("active");
//       } else {
//         headerRef.current?.classList?.remove("active");
//         goTopBtnRef.current?.classList?.remove("active");
//       }
//     };

//     window.addEventListener("scroll", handleScroll);
//     return () => {
//       window.removeEventListener("scroll", handleScroll);
//     };
//   }, []);

//   // Open/close the mobile menu
//   useEffect(() => {
//     const navOpenBtn = navOpenBtnRef.current;
//     const navCloseBtn = navCloseBtnRef.current;
//     const overlay = overlayRef.current;

//     navOpenBtn.addEventListener("click", toggleNavbar);
//     navCloseBtn.addEventListener("click", toggleNavbar);
//     overlay.addEventListener("click", toggleNavbar);

//     return () => {
//       navOpenBtn.removeEventListener("click", toggleNavbar);
//       navCloseBtn.removeEventListener("click", toggleNavbar);
//       overlay.removeEventListener("click", toggleNavbar);
//     };
//   }, []);

//   // Close dropdown and navbar after navigation
//   const handleNavLinkClick = () => {
//     setOpenDropdown(null); // Close the dropdown
//     toggleNavbar(); // Close the mobile navbar if it's open
//   };

//   return (
//     <header className="header" data-header ref={headerRef}>
//       <div ref={overlayRef} className="overlay" data-overlay></div>
//       <div className="header-top">
//         <div className="container">
//           <div className="helpline-box pointer" onClick={handleClick}>
//             <div className="icon-box">
//               {/* <IoCallOutline size={24} /> */}
//               <IoLogoWhatsapp size={24} />
//             </div>
//             <div className="wrapper">
//               <p className="helpline-title">For Further Inquiries:</p>
//               <p className="helpline-number">+91 961 9982 990</p>
//             </div>
//           </div>
//           <Link to="/" className="logo size-45">
//             <img src="/assets/images/nafa_logo.png" alt="Nafa logo" />
//           </Link>
//           <div className="header-btn-group">

//           <form onSubmit={handleSearchSubmit} className="search-form">
//               <button className="search-btn" aria-label="Search">
//                 <IoSearch size={28} />
//               </button>
//               <input
//                 type="text"
//                 value={searchQuery}
//                 onChange={handleChange}
//                 placeholder="Search on Google..."
//                 className="search-input"
//               />
//             </form>
//             {/* <button className="search-btn" aria-label="Search">
//               <IoSearch size={28} />
//             </button> */}
//             <button
//               ref={navOpenBtnRef}
//               className="nav-open-btn"
//               aria-label="Open Menu"
//               data-nav-open-btn
//             >
//               <IoMenuOutline size={30} />
//             </button>
//           </div>
//         </div>
//       </div>
//       <div className="header-bottom">
//         <div className="container">
//           <ul className="social-list">
//             {socialLinks.map(({ name, url, icon }) => (
//               <li key={name}>
//                 <button
//                   className="social-link"
//                   onClick={() => window.open(url, "_blank")}
//                   aria-label={`Visit us on ${name}`}
//                 >
//                   {icon}
//                 </button>
//               </li>
//             ))}
//           </ul>
//           <nav ref={navbarRef} className="navbar" data-navbar>
//             <div className="navbar-top">
//               <Link to="/" className="logo">
//                 <img src="/assets/images/nafa_logo.png" alt="Nafa logo" />
//               </Link>
//               <button
//                 ref={navCloseBtnRef}
//                 className="nav-close-btn"
//                 aria-label="Close Menu"
//                 data-nav-close-btn
//               >
//                 <IoCloseOutline size={24} />
//               </button>
//             </div>

//             <ul className="navbar-list">
//               {navLinks.map(({ name, path, subMenu }, index) => (
//                 <li key={name} className={subMenu ? "has-dropdown" : ""}>
//                   <Link
//                     to={path}
//                     className="navbar-link"
//                     onClick={(e) => {
//                       if (subMenu) {
//                         e.preventDefault(); // Prevent navigation on dropdown
//                         toggleDropdown(index);
//                       } else {
//                         handleNavLinkClick(); // Close dropdown and navbar on simple navigation
//                       }
//                     }}
//                   >
//                     {name}
//                   </Link>
//                   {subMenu && openDropdown === index && (
//                     <ul className="dropdown-menu">
//                       {subMenu.map((item) => (
//                         <li key={item.name}>
//                           <Link
//                             to={item.path}
//                             className="dropdown-link"
//                             onClick={handleNavLinkClick} // Close the dropdown after clicking the item
//                           >
//                             {item.name}
//                           </Link>
//                         </li>
//                       ))}
//                     </ul>
//                   )}
//                 </li>
//               ))}
//             </ul>
//           </nav>
//           <Link to="news">
//             <p className="btn btn-primary">News and Events</p>
//           </Link>
//           {/* <Link to="/services/news">
//             <p className="btn btn-primary">News and Events</p>
//           </Link> */}
//         </div>
//       </div>
//     </header>
//   );
// }
import React, { useEffect, useRef, useState } from "react";
import {
  IoCloseOutline,
  IoLogoWhatsapp,
  IoMenuOutline,
  IoSearch,
} from "react-icons/io5";
import { Link } from "react-router-dom";
import "../../App.css";
import { navLinks, socialLinks } from "../../shared/services/services";
import "./Header.css";

export default function Header() {
  const [searchQuery, setSearchQuery] = useState("");
  const overlayRef = useRef(null);
  const navbarRef = useRef(null);
  const navOpenBtnRef = useRef(null);
  const navCloseBtnRef = useRef(null);
  const headerRef = useRef(null);
  const goTopBtnRef = useRef(null);

  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleDropdown = (index) => {
    setOpenDropdown((prev) => (prev === index ? null : index));
  };

  const toggleNavbar = () => {
    navbarRef.current?.classList?.toggle("active");
    overlayRef.current?.classList?.toggle("active");
  };

  const handleClick = () => {
    const phoneNumber = "+919619982990";
    const message =
      "Hello, I want to know more information about Nafa Tourism Consultancy !";
    window.location.href = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
  };

  const handleSearchSubmit = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      window.open(`https://www.google.com/search?q=${searchQuery}`, "_blank");
    } else {
      alert("Please enter a search query.");
    }
  };

  const handleChange = (e) => {
    setSearchQuery(e.target.value);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 200) {
        headerRef.current?.classList?.add("active");
        goTopBtnRef.current?.classList?.add("active");
      } else {
        headerRef.current?.classList?.remove("active");
        goTopBtnRef.current?.classList?.remove("active");
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    const navOpenBtn = navOpenBtnRef.current;
    const navCloseBtn = navCloseBtnRef.current;
    const overlay = overlayRef.current;

    navOpenBtn.addEventListener("click", toggleNavbar);
    navCloseBtn.addEventListener("click", toggleNavbar);
    overlay.addEventListener("click", toggleNavbar);

    return () => {
      navOpenBtn.removeEventListener("click", toggleNavbar);
      navCloseBtn.removeEventListener("click", toggleNavbar);
      overlay.removeEventListener("click", toggleNavbar);
    };
  }, []);

  const handleNavLinkClick = () => {
    setOpenDropdown(null);
    toggleNavbar();
  };

  return (
    <header className="header" data-header ref={headerRef}>
      <div ref={overlayRef} className="overlay" data-overlay></div>
      <div className="header-top">
        <div className="container">
          <div className="helpline-box pointer" onClick={handleClick}>
            <div className="icon-box">
              <IoLogoWhatsapp size={24} />
            </div>
            <div className="wrapper">
              <p className="helpline-title">For Further Inquiries:</p>
              <p className="helpline-number">+91 961 9982 990</p>
            </div>
          </div>
          <Link to="/" className="logo size-45">
            <img src="/assets/images/nafa_logo.png" alt="Nafa logo" />
          </Link>
          <div className="header-btn-group">
            <form onSubmit={handleSearchSubmit} className="search-form">
              <button className="search-btn" aria-label="Search">
                <IoSearch size={28} />
              </button>
              <input
                type="text"
                value={searchQuery}
                onChange={handleChange}
                placeholder="Search on Google..."
                className="search-input"
              />
            </form>
            <button
              ref={navOpenBtnRef}
              className="nav-open-btn"
              aria-label="Open Menu"
              data-nav-open-btn
            >
              <IoMenuOutline size={30} />
            </button>
          </div>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <ul className="social-list">
            {socialLinks.map(({ name, url, icon }) => (
              <li key={name}>
                <button
                  className="social-link"
                  onClick={() => window.open(url, "_blank")}
                  aria-label={`Visit us on ${name}`}
                >
                  {icon}
                </button>
              </li>
            ))}
          </ul>
          <nav ref={navbarRef} className="navbar" data-navbar>
            <div className="navbar-top">
              <Link to="/" className="logo">
                <img src="/assets/images/nafa_logo.png" alt="Nafa logo" />
              </Link>
              <button
                ref={navCloseBtnRef}
                className="nav-close-btn"
                aria-label="Close Menu"
                data-nav-close-btn
              >
                <IoCloseOutline size={24} />
              </button>
            </div>
            <ul className="navbar-list">
              {navLinks.map(({ name, path, subMenu }, index) => (
                <li key={name} className={subMenu ? "has-dropdown" : ""}>
                  <Link
                    to={path}
                    className="navbar-link"
                    onClick={(e) => {
                      if (subMenu) {
                        e.preventDefault();
                        toggleDropdown(index);
                      } else {
                        handleNavLinkClick();
                      }
                    }}
                  >
                    {name}
                  </Link>
                  {subMenu && openDropdown === index && (
                    <ul className="dropdown-menu">
                      {subMenu.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.path}
                            className="dropdown-link"
                            onClick={handleNavLinkClick}
                          >
                            {item.name}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
          <Link to="news">
            <p className="btn btn-primary">News and Events</p>
          </Link>
        </div>
      </div>
    </header>
  );
}
