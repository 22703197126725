import React, { useEffect } from "react";
import "./TourismConsultation.css";
export default function TourismConsultation() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tourism-consultation-container ">
      <header className="tourism-consultation-header">
        <p className="section-subtitle">Tourism Project Consultation</p>
        <h2 className="h2 section-title">
          Competent project management consulting ensures smooth completion.
        </h2>
      </header>

      <section className="tourism-consultation-section">
        <h3 className="section-sub-heading">About Us</h3>
        <p className="section-text w-80 text-justify">
          We specialize in tourism project consultation that is diverse,
          flexible, and purposeful. Our strategy is holistic, comprehensive, and
          views projects as dynamic phenomena involving human engagement and
          partnership.
        </p>
        <p className="section-text w-80 text-justify">
          N A F A's expertise entails the entire scope of project consultancy
          work. The project team at N A F A partners with customers to build
          cost-effective, scalable models and establish industry-leading
          practices crafted to improve tourism project management operations and
          make the best use of resources.
        </p>
      </section>

      <section className="tourism-consultation-section">
        <h3 className="section-sub-heading">Our Approach</h3>
        <p className="section-text w-80 text-justify">
          Our approach is based on best practices, resulting in a quality
          program that is secure, cost-efficient, technically proficient, and
          environmentally compliant. We ensure deliverables in a short period,
          fulfilling the client's preferences and business objectives most
          effectively.
        </p>
      </section>

      <section className="tourism-consultation-section">
        <h3 className="section-sub-heading">Our Solutions</h3>
        <p>We provide comprehensive tourism project consulting, including:</p>
        <ul className="tourism-consultation-ul">
          <li>Pre-feasibility study</li>
          <li>New Project Classification</li>
          <li>Project Feasibility and Market Research</li>
          <li>Identification of Lucrative Project Opportunities</li>
          <li>
            Processing of Project Models / Pre-Investment and Pre-Feasibility
            Research
          </li>
          <li>Market Surveys / Research</li>
          <li>Identification and Screening of Process</li>
          <li>Basic Assistance</li>
        </ul>
        <p>Get in touch with our experts to see how they can assist you!</p>
      </section>

      <section className="tourism-consultation-section">
        <h3 className="section-sub-heading">Our Focus Areas</h3>
        <p className="section-text w-80 text-justify">
          We thoroughly understand your objectives before proposing customized
          solutions that are most fitted to them. We focus on the fundamental
          sustainability of the tourism business, and our services target three
          main areas:
        </p>
        <div className="tourism-consultation-focus-areas">
          <div className="tourism-consultation-focus-area">
            <h3>1. Idea Conceptualization</h3>
            <p>
              We assess proposals and transform them into practical initiatives
              that can be executed, no matter where the project is in its
              development.
            </p>
          </div>
          <div className="tourism-consultation-focus-area">
            <h3>2. Marketing</h3>
            <p>
              With strategic communications approaches and marketing efforts
              aimed at long-term involvement and real outcomes, we boost the
              value of initiatives.
            </p>
          </div>
          <div className="tourism-consultation-focus-area">
            <h3>3. Management</h3>
            <p>
              We effectively develop projects using a comprehensive methodology
              based on focused strategy, concise processes, and innovative
              solutions, harnessing fair human contact.
            </p>
          </div>
        </div>
      </section>

      <section className="tourism-consultation-services-section">
        <h3 className="section-sub-heading">Additional Services</h3>
        <ul className="tourism-consultation-ul">
          <li>Providing tourism-related advice and opportunities</li>
          <li>
            Assisting in the transition of businesses to become more sustainable
          </li>
          <li>Management and business planning</li>
        </ul>
      </section>
    </div>
  );
}
