import React, { useEffect } from "react";
import "./Tourism.css";
export default function Tourism() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="tourism-container">
      <p className="section-subtitle">Tourism</p>

      <div className="tourism-header">
        <h2 className="h2 section-title">
          {" "}
          Empowering Sustainable Tourism & Travel Business
        </h2>
      </div>

      <div className="tourism-content">
        <p className="section-text w-80 text-justify">
          International and Domestic travel has been growing tremendously. The
          emergence of developing global markets as significant potential
          outbound markets makes it increasingly challenging to have a footprint
          in each region. At the same time, the cost of opening a foreign office
          is soaring. We know how to uncover and access opportunities for a
          long-term and successful tourism or travel industry business thanks to
          our years of professional expertise in international and domestic
          tourism.
        </p>

        <p className="section-text w-80 text-justify">
          N A F A aids in the establishment of sustainable tourism operations as
          well as the sustainability of current models. We empower destinations,
          airlines, hotels, and tour operators in identifying their core
          visitors, creating demand, providing numerous opportunities to connect
          with the destination, and making their experience easy to book. Our
          understanding of local and international travel and tourism empowers
          us to put together effective campaigns that reach your target audience
          through direct-to-consumer inputs and the travel sector.
        </p>

        <p className="section-text w-80 text-justify">
          We take delight in developing positive contact with travel industry
          professionals, passengers, guests, end-users plus customers,
          specializing in one-on-one discussions and coaching. To uphold our
          customers' confidence, we believe in complete honesty and openness
          with the travel industry and our customers.
        </p>

        <h3 className="section-sub-heading">How Can We Help?</h3>
        <p className="section-text w-80 text-justify">
          We provide tourism representation, public relations, advertising, and
          marketing services to tour operators (national and state), hotels and
          resorts, tourist destinations, and destination management firms
          looking to establish their business and market share in India.
        </p>

        <p className="section-text w-80 text-justify">
          Our team is highly qualified to manage all sorts of operations,
          including tourist strategy, media relations, sales, and marketing
          campaigns across industry and client platforms, owing to our B2B and
          B2C expertise. At N A F A, our primary goal is to make it easier for
          destinations and tourist products from across the globe to enter the
          Indian market in the most cost-effective manner possible.
        </p>
      </div>
    </div>
  );
}
