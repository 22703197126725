import React, { useEffect } from "react";
import "./Contact.css";
export default function Contact() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="contact-body">
        <div className="contact-container">
          <div className="contact-content">
            <div className="contact-card">
              <h3>Office Address</h3>
              <p>NAFA Tourism Consultancy</p>
              <p>A2 - 601, D S K Madhuabn,</p>
              <p>A. K. Road, Near Saki Naka Junction,</p>
              <p>Mumbai 400072.</p>
              <p>Maharashtra, INDIA</p>
              <hr />
              <h3>Contact Details</h3>
              <p>
                <strong>WhatsApp/Tel:</strong> +91 9619 982 990
              </p>
              <p>
                <strong>Email (Sales & Enquiry):</strong>{" "}
                <a href="mailto:shaikh@nafatourism.com">
                  shaikh@nafatourism.com
                </a>
              </p>
              <hr />
              <h3>Managing Director</h3>
              <p>
                <strong>Name:</strong> Prof. SHAIKH RAHMATULLAH
              </p>
              <p>
                <strong>Email:</strong>{" "}
                <a href="mailto:shaikh@nafatourism.com">
                  shaikh@nafatourism.com
                </a>
              </p>
              <p>
                <strong>Mobile & WhatsApp:</strong> +91 9619 982 990
              </p>
              <p>
                <strong>Facebook:</strong>{" "}
                <a
                  href="https://www.facebook.com/profile.php?id=100063900166935"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  www.facebook.com/NAFA-Tourism
                </a>
              </p>
            </div>

            {/* Google Maps Section */}
            {/* <div className="map-container">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3408.6462057265703!2d72.9703249!3d19.2529181!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7bbeae49be2b9%3A0x4de0a353debb291c!2sN%20A%20F%20A%20Tourism%20Consultancy!5e1!3m2!1sen!2sin!4v1735298091580!5m2!1sen!2sin"
                width="100%" // Changed to make the map responsive
                height="450"
                style={{ border: "0" }}
                allowFullScreen
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}
