import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { pathTitles } from "../../shared/services/services";
import "./Banner.css";

export default function Banner() {
  const location = useLocation();
  const currentPath = location.pathname;
  const currentPage = pathTitles?.[currentPath] || {
    title: "Journey to Explore World",
    description: `<strong>NAFA - Tourism Consultancy</strong> India’s leading specialist in travel and tourism marketing, training, and consultancy. With a team of seasoned industry experts, we provide comprehensive guidance in tourism, leisure, and hospitality.`,
  };

  const scrollToContent = () => {
    // Scroll to the main content section
    const contentSection = document.getElementById("main-content");
    if (contentSection) {
      contentSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="hero" id="home">
        <div className="container">
          <h2 className="h1 hero-title">{currentPage.title}</h2>

          <p
            className="hero-text t-align-justify"
            dangerouslySetInnerHTML={{ __html: currentPage.description }}
          ></p>

          <div className="btn-group">
            <button className="btn btn-primary" onClick={scrollToContent}>
              Learn More
            </button>
          </div>
        </div>
      </section>

      <div id="main-content"></div>

      {/* Main Content Section */}
    </>
  );
}
