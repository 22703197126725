import { Check } from "@mui/icons-material";
import React, { useEffect } from "react";
import "./TourismResearch.css";

const TourismResearch = () => {
  const textStyles = {
    textAlign: "justify",
    lineHeight: "1.5rem",
    padding: "0px 50px",
    display: "flex",
    flexDirection: "Column",
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="package" id="package">
        <div className="container">
          <p className="section-subtitle">Tourism Research</p>

          <h2 className="h2 section-title">Empowering Tourism with Insights</h2>

          <p className="section-text w-80 text-justify">
            We are a market research firm that provides valuable data to travel
            and tourism companies so they can grow and expand their marketing
            strategies. Our research services enable businesses to better
            understand their clients and create wise decisions that contribute
            to stronger marketing and advertising campaigns.
          </p>

          <ul className="research-points" style={textStyles}>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li> Outgoing traveler accessibility</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>
                The availability of accommodations, activities,
                restaurants/bars, and transit options, among other things
              </li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>Environmental impacts</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>
                What factors influence an Indian tourist's decision to visit
                your destination?
              </li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>What are their preferred booking platforms and methods?</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>What are their primary sources of information?</li>
            </div>
          </ul>

          <p className="section-text">
            Our target market research services equip tourism and hospitality
            leaders with the insights they require to develop unique strategies
            for success in a competitive industry. We empower our customers in
            developing and implementing company goals that drive down costs,
            fulfill evolving customer wants, and portray offers by delivering
            insightful market insights suited to their dynamic objectives.
            Harness the true value of market intelligence and welcome innovative
            business opportunities.
          </p>

          <p className="section-text">
            Leverage market analysis to market your business more effectively.
            Provide your tourism business with a competitive edge as we connect
            data with an emphasis on tourism trends to assess individual
            travellers’ interests and preferences, such as:
          </p>

          <ul className="services-list" style={textStyles}>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>Competitor Research</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>SWOT-Analysis</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>Destination Positioning</li>
            </div>
            <div className="flex-gap ">
              <Check htmlColor="grey" />
              <li>Target Market Research</li>
            </div>
          </ul>

          <p className="section-text">
            As a result of this vital information, our team will devise
            constructive means to outperform the competitors and address other
            business-related challenges. The aforementioned analysis will result
            in a structure that outlines the best route to establish your brand
            in the Indian market while also defining your products and services.
          </p>
        </div>
      </section>
    </>
  );
};

export default TourismResearch;
