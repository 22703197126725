import React, { useEffect } from "react";
import "./TourismMarketing.css";
export default function TourismMarketing() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tourism-marketing-container">
      <p className="section-subtitle">Tourism Marketing</p>

      <div className="tourism-marketing-header">
        <h2 className="h2 section-title">
          Connecting Your Business with the Right Audience
        </h2>
      </div>

      <div className="tourism-marketing-content">
        <p className="section-text w-80 text-justify">
          Marketing is critical to achieving this, and many of the greatest
          tourism marketing strategies center on guiding businesses in
          identifying and promoting their unique selling point. Of course,
          keeping up with the newest trends is critical for marketers who want
          to establish a diversified marketing mix and employ the most effective
          techniques for making their voices heard across various channels.
        </p>

        <p className="section-text w-80 text-justify">
          N A F A empowers you in connecting with your audience and helping your
          tourism business stand out with the right approach.
        </p>
        <h3 className="section-sub-heading">
          Key Benefits of Tourism Marketing
        </h3>
        <ul>
          <li>
            <strong>Tailored Approaches</strong>: Personalized digital marketing
            tactics tailored to your tour and travel business's specific
            requirements – no more one-size-fits-all approaches.
          </li>
          <li>
            <strong>Versatile and Dynamic</strong>: You have the authority to
            decide where your marketing budget goes each month since tourism is
            a constantly evolving, frequently seasonal industry.
          </li>
          <li>
            <strong>Data-driven Marketing</strong>: Our experts apply the most
            up-to-date data and techniques to ensure you're targeting the
            greatest potential demographic for your tours and events.
          </li>
        </ul>
      </div>
    </div>
  );
}
