import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube } from "react-icons/io5";

export const socialLinks = [
  {
    name: "Facebook",
    url: "https://www.facebook.com/profile.php?id=100063900166935",
    icon: <IoLogoFacebook size={24} />,
  },
  {
    name: "Twitter",
    url: "https://twitter.com",
    icon: <IoLogoTwitter size={24} />,
  },
  {
    name: "YouTube",
    url: "https://youtube.com",
    icon: <IoLogoYoutube size={24} />,
  },
];

export const navLinks = [
  { name: "Home", path: "/" },
  { name: "About Us", path: "/about" },
  {
    name: "Our Services",
    subMenu: [
      {
        name: "Travel, Hospitality and Tourism Trainings",
        path: "/services/training",
      },
      { name: "Tourism Research", path: "/services/tourism-research" },
      { name: "Digital Business", path: "/services/digital-business" },
      { name: "Social Media", path: "/services/social-media" },
      {
        name: "Tourism Project Consultation",
        path: "/services/tourism-consultation",
      },
      { name: "Tourism Marketing", path: "/services/tourism-marketing" },
      { name: "Tourism", path: "/services/tourism" },
      //  Need to Check
      // { name: "Content Writing", path: "/services/content-writing" },
      // { name: "Project Consultation", path: "/services/project-consultation" },

      // {
      //   name: "Tourism Consultation and Representation",
      //   path: "/services/tourism-consultation",
      // },
      // {
      //   name: "Tourism P.R. and Media Relations",
      //   path: "/services/tourism-pr-media",
      // },
    ],
  },
  { name: "Gallery", path: "/gallery" },
  { name: "Contact Us", path: "/contact" },
];

export const galleryImages = [
  {
    src: "./assets/images/galleryImg/galleryImg (1).jpeg",
    alt: "Gallery image 1",
  },
  {
    src: "./assets/images/galleryImg/galleryImg (10).jpeg",
    alt: "Gallery image 2",
  },
  {
    src: "./assets/images/galleryImg/galleryImg (49).jpeg",
    alt: "Gallery image 3",
  },

  {
    src: "./assets/images/galleryImg/galleryImg (69).jpeg",
    alt: "Gallery image 4",
  },
  {
    src: "./assets/images/galleryImg/galleryImg (8).jpeg",
    alt: "Gallery image 5",
  },
  {
    src: "./assets/images/galleryImg/galleryImg (55).jpeg",
    alt: "Gallery image 6",
  },
  {
    src: "./assets/images/galleryImg/galleryImg (41).jpeg",
    alt: "Gallery image 7",
  },
];

export const pathTitles = {
  "/": {
    title: "Journey to Explore the World",
    description: `<strong>NAFA - Tourism Consultancy</strong> is India’s leading specialist in travel and tourism marketing, training, and consultancy. With a team of seasoned industry experts, we provide comprehensive guidance in tourism, leisure, and hospitality.`,
  },
  "/about": {
    title: "About Us",
    description:
      "At NAFA, we specialize in Training, Marketing, PR, and communications for India’s tourism and hospitality sector. Backed by a seasoned team with deep regional and international expertise, we deliver innovative strategies and measurable results.",
  },
  "/services/training": {
    title: "Training",

    description:
      "Master the fundamentals of tourism management, make informed decisions on sustainable practices, and develop practical strategies to drive growth. This course empowers organizations to implement realistic, sustainable tourism initiatives for long-term success in the evolving travel industry",
  },
  "/services/tourism-research": {
    title: "Tourism Research",
    description:
      "Enhancing your destination's success by analyzing traveler insights, understanding market trends, and providing strategic guidance for your business growth and development.",
  },
  "/services/digital-business": {
    title: "Digital Business",
    description:
      "An innovative and engaging travel solution designed to drive successful online marketing for your destination, leveraging digital strategies to enhance visibility and attract global travelers.",
  },
  "/services/social-media": {
    title: "Social Media",
    description:
      "N A F A creates tailored social media strategies for travel, hospitality, and tourism businesses, helping enhance online presence, engage customers, and maximize the impact of digital marketing campaigns.",
  },
  "/services/career-adviser": {
    title: "Career Adviser",
    description:
      "N A F A offers expert digital tourism career guidance in India, helping you expand your skills and knowledge in the tourism industry through specialized education, practical experiences, and sustainable practices.",
  },
  "/services/tourism-marketing": {
    title: "Tourism Marketing",
    description: `Marketing empowers you to reach targeted audiences and expand your tourism business. At N A F A, we develop fast, customized strategies that effectively address challenges and integrate key aspects of the tours and activities sector.`,
  },
  "/services/content-writing": {
    title: "Content Writing",
    description:
      "We offer budget-friendly, high-quality travel and tourism content writing services, ensuring the finest writing experience. In a digital world, effective content marketing is essential for attracting and engaging today’s tech-savvy travelers.",
  },
  "/services/project-consultation": {
    title: "Project Consultation",
    description:
      "N A F A specializes in flexible, comprehensive tourism project consultation, ensuring smooth, efficient completion. We create cost-effective, scalable models and implement industry-leading practices to enhance project management and achieve client objectives.",
  },
  "/services/tourism-consultation": {
    title: "Tourism Consultation",
    description:
      "N A F A offers strategic tourism consultation and representation, helping businesses establish sustainable operations, create demand, and connect with target audiences. We provide tailored marketing, PR, and sales services to grow in India.",
  },
  "/services/tourism-pr-media": {
    title: "Tourism PR & Media",
    description:
      "Tourism PR, also known as 'earned media,' involves engaging with media, bloggers, and influencers to share your message. Effective publicity creates desire, with stories that take expert connections and strategic media outreach.",
  },
  "/gallery": {
    title: "Gallery",
    description:
      "Understand the role of the hospitality sector in tourism, designing customized itineraries for individuals and groups. Learn tourism geography, destination promotion, customer handling and communication skills. Embrace offering diverse services and adopt continuous learning. Thoroughly absorb tourism and travel skills  </br> Discover unforgettable moments captured by our travelers across the globe, showcasing breathtaking destinations, unique experiences, and the vibrant essence of travel and hospitality.",
  },
  "/contact": {
    title: "Contact Us",
    description:
      "We’d love to hear from you! Whether you have questions, need more information about our services, or are ready to collaborate, reach out to us anytime. Our team is here to assist you.",
  },
  "/news": {
    title: "News & Events",
    description:
      "The integration of online teaching in tourism education offers significant benefits such as flexibility and accessibility, though it also brings challenges. By adopting effective strategies, such as blended learning and interactive tools, educators can overcome these obstacles. Continuous improvement of online teaching practices will ultimately provide students with the knowledge and skills required to excel in the tourism industry",
  },
};

export const packages = [
  {
    image: "/assets/images/Training.webp",
    alt: "Training for the Tourism Industry",
    title: "Training for the Tourism Industry",
    description:
      " The course looks at how to establish a revolutionary framework for business travel that taps the true potential of a successful business travel program that meets the advanced requirements of travel using the cornerstones of the Travel and Tourism Industry",
    list: [
      // { label: "Potential", value: "Create high-quality opportunities." },
      // {
      //   label: "Challenge",
      //   value: "Fuel growth with skilled human capital.",
      // },
    ],
    additionalText: `
    <ul>
      <li classname="list-pointers"> •  Get an in-depth understanding of the industry, and is the benchmark for tourism and travel management.</li>
      <li classname="list-pointers"> •  Make smart decisions on how to incorporate sustainable principles into your business or destination.</li>
      <li classname="list-pointers"> •  Empower your organization to build realistic and practical sustainable tourism strategies and initiatives.</li>
      <li classname="list-pointers"> •  Tailored for hospitality and accommodation personnel, this framework offers concrete guidance on hotel sustainable practices.</li>
      <li classname="list-pointers"> •  Focus on the hospitality parameters with operational metrics for hotels and accommodation.</li>
      <li classname="list-pointers"> •  Expert lectures, relevant resources, and real-world industry instances and insights are also included.</li>
      <li classname="list-pointers"> •  We provide many soft skills training programs to help you boost your communication, motivation, team management, projects, negotiations, problem-solving skills, and tactical awareness. Training can help you communicate more effectively.</li>
    </ul>
  `,
  },
];

export const aboutUsData = {
  title: "About Us",
  description: `N A F A Tourism Consultancy is the leading specialist from India, offering travel and tourism marketing, training, and consultancy. We advise on tourism, leisure, and hospitality, delivered by our experienced team of industry and communications professionals. We also advise India-based corporates for international market entry, as our team has international experience of working with different cultures and clients in the international marketplace, with a practical understanding of both the markets and how to adapt to each.`,
  belief:
    "We believe that the strength of a marketing and communication strategy lies in its alignment with the business strategy.",
  operations:
    "N A F A's core operations are from Mumbai, Maharashtra, and Western India, but we understand and have contacts throughout India and International Markets.",
  reasons: [
    "We are one of the region's leading experts in the tourism and hospitality industry.",
    "Our integrated communications and representation services provide the platform for you to benefit from a better return on investment.",
    "Our creative ideas help generate and boost bottom-line revenues.",
    "Our team offers a depth of experience in the tourism industry, both regionally and internationally.",
    "N A F A's senior management team brings combined experience from marketing and sales, all types of training, and PR, both at an in-house and consultancy capacity.",
    "Specialist leisure and tourism industry practitioners.",
    "The team has in-depth understanding of India and its cultural nuances, as well as international experience.",
    "Established track record, advising a range of clients from the tourism sector.",
    "Extensive contacts across all stakeholder groups.",
    "We are committed practitioners focused on delivering results to agreed business objectives.",
    "N A F A is independently owned by its founder, who are Indians with great national and international exposure.",
  ],
};
export const teamData = {
  intro: `Our team holds the credit of handling, promoting, marketing, and selling a Far Eastern Country for a number of years. In addition, our team has also handled, marketed, and promoted various hotel groups from UK, Malaysia, Thailand, Morocco, and the UAE.`,
  expertise: [
    "Our team of senior industry experts will develop the strategic approach for your programme.",
    "We have the industry know-how and have extensive contacts with the appropriate stakeholders as well as the media.",
    "We are also specialists in steering outbound travel from India to destinations around the world.",
    "We have the necessary expertise to launch and reposition your brand.",
  ],
  sectors: [
    "Travel and Tourism Trainings",
    "Education",
    "Leisure",
    "Hotels",
    "Tourism Boards",
    "Tourism Digital Business",
    "Recreation",
    "Sports",
    "Medical Tourism",
    "Career Advisors",
    "Tourism",
    "Hospitality",
    "Tourism Research",
    "Tourism Intelligence",
    "Tourism Marketing",
    "MICE",
    "Health and Wellness",
  ],
  teamMember: {
    name: "Prof. SHAIKH RAHMATULLAH",
    bio: [
      "An astute professional, with total 45+ years of experience in Tourism, Hospitality, Airlines, and Travel Industry, of which FOURTEEN years of experience in Training, Capacity Building, and Coaching students.",
      "Business Development, Marketing & Sales in Tourism, Travel & Tour Operations, Off-line Airlines Marketing & Sales of Cruise Industry including Promotion, Hospitality & Client Relationship Management.",
      "Responsible for marketing International Hotel Groups.",
      "Proficient in handling a wide range of travel operations including VIP and Niche clients with reservations, ticketing etc.",
      "Coached, lectured, and trained Tourism Students of the Universities, Travel Academies, and related organizations.",
      "Volunteered with an International NGO - VSO - in Tajikistan, as an adviser to The Committee of Youth Affairs, Sports, and Tourism.",
      "A World Traveller, holds the distinction of travelling to over sixty+ countries of the world.",
    ],
  },
};

// PENDING

// src/data.js
export const pageData = {
  title: "Online and Tourism Teaching Activities",
  introduction:
    "The advent of technology has significantly transformed various sectors, including education and tourism. Online teaching has become a prominent method for delivering educational content, offering flexibility and accessibility. Similarly, tourism education has also embraced online platforms to enhance learning experiences. This essay explores the integration of online teaching activities in tourism education, highlighting its benefits, challenges, and potential strategies for effective implementation",
  sections: [
    {
      title: "Benefits of Online Teaching in Tourism Education",
      content: [
        {
          subtitle: "Accessibility and Flexibility",
          details:
            "Online teaching allows students to access course materials from anywhere in the world, making it convenient for those who may not be able to attend traditional classes due to geographical or time constraints.",
          details2:
            "Flexibility in scheduling enables students to learn at their own pace, accommodating different learning styles and paces.",
        },
        {
          subtitle: "Diverse Learning Resources",
          details:
            "The internet offers a vast array of resources, including virtual tours, interactive maps, and multimedia content, which can enrich the learning experience.",
          details2:
            "Online platforms facilitate access to guest lectures, webinars, and interviews with industry professionals, providing students with real-world insights.",
        },
        {
          subtitle: "Cost-Effectiveness",
          details:
            "Online education can reduce costs associated with travel, accommodation, and physical learning materials.",
          details2:
            "Institutions can reach a wider audience without the need for extensive physical infrastructure.",
        },
        {
          subtitle: "Enhanced Collaboration and Networking",
          details:
            "Online platforms often include forums, discussion boards, and group projects, fostering collaboration among students from diverse backgrounds.",
          details2:
            "Networking opportunities with peers and professionals globally can lead to valuable connections in the tourism industry.",
        },
      ],
    },

    //Challenges of Online Teaching in Tourism Education
    {
      title: "Challenges of Online Teaching in Tourism Education",
      content: [
        {
          subtitle: "Technical Issues",
          details:
            "Reliable internet access and adequate technology are essential for effective online learning. Technical difficulties can disrupt the learning process.",
          details2:
            "Not all students may have access to high-quality devices or stable internet connections.",
        },
        {
          subtitle: "Engagement and Interaction",
          details:
            "Maintaining student engagement in an online environment can be challenging. The lack of face-to-face interaction may lead to feelings of isolation",
          details2:
            "Instructors need to employ innovative methods to keep students motivated and actively participating.",
        },
        {
          subtitle: "Assessment and Feedback",
          details:
            "Assessing student performance and providing timely, constructive feedback can be more complex in an online setting.",
          details2:
            "Ensuring academic integrity during online assessments requires robust measures to prevent cheating and plagiarism.",
        },
        {
          subtitle: "Practical Components",
          details:
            "oTourism education often involves practical, hands-on experiences such as field trips, internships, and site visits, which are difficult to replicate online.",
          details2:
            "Virtual simulations and case studies can partially address this, but they may not fully substitute real-world experiences.",
        },
      ],
    },

    // Strategies for Effective Online Tourism Teaching
    {
      title: "Strategies for Effective Online Tourism Teaching",
      content: [
        {
          subtitle: "Interactive and Multimedia Content",
          details:
            "Utilize videos, virtual tours, and interactive simulations to make learning more engaging and practical.",
          details2:
            "Incorporate gamification techniques to enhance motivation and participation.",
        },
        {
          subtitle: "Blended Learning Approach",
          details:
            "Combine online learning with occasional in-person sessions or field trips to provide a balanced educational experience.",
          details2:
            "Use hybrid models to take advantage of the flexibility of online learning while retaining the benefits of face-to-face interaction.",
        },
        {
          subtitle: "Regular Communication and Support",
          details:
            "Establish clear communication channels between instructors and students to address queries and provide support.",
          details2:
            "Regular check-ins, virtual office hours, and feedback sessions can help maintain engagement and address any issues promptly.",
        },
        {
          subtitle: "Professional Development for Educators",
          details:
            "Train educators in using online teaching tools and methodologies effectively.",
          details2:
            "Encourage continuous professional development to keep up with technological advancements and pedagogical trends.",
        },
      ],
    },
    // Topics and Modules for Tourism, Travel, and Hospitality Education
    {
      title:
        "Topics and Modules for Tourism, Travel, and Hospitality Education",
      content: [
        {
          subtitle: "Introduction to Tourism and Hospitality",
          details: "Definition and scope of tourism",
          details2: "History and development of tourism",
          details3: "Types of tourism (leisure, business, adventure, etc.)",
          details4: "Key players in the tourism industry",
        },
        {
          subtitle: "Tourism Management",
          details: "Destination management and marketing",
          details2: "Tourism planning and development",
          details3: "Sustainable tourism practices",
          details4: "Economic, social, and environmental impacts of tourism",
        },
        {
          subtitle: "Hospitality Management",
          details: "Overview of the hospitality industry",
          details2: "Hotel and lodging management",
          details3: "Food and beverage management",
          details4: "Customer service and quality management",
        },
        {
          subtitle: "Travel Agency and Tour Operations",
          details: "Role and functions of travel agencies",
          details2: "Tour planning and itinerary design",
          details3: "Ticketing and reservations systems",
          details4: "Legal and ethical considerations in travel operations",
        },
        {
          subtitle: "Event Management",
          details: "Types of events (corporate, social, cultural, etc.)",
          details2: "Event planning and coordination",
          details3: "Marketing and promotion of events",
          details4: "Risk management and contingency planning",
        },
        {
          subtitle: "Marketing in Tourism and Hospitality",
          details: "Market research and consumer behavior",
          details2: "Branding and positioning of tourism products",
          details3: "Digital marketing and social media strategies",
          details4: "Sales techniques and customer relationship management",
        },
        {
          subtitle: "Tourism Policy and Planning",
          details: "Role of government in tourism development",
          details2: "Policy frameworks and regulatory issues",
          details3: "Community involvement and stakeholder engagement",
          details4: "Crisis management in tourism",
        },
        {
          subtitle: "Cultural and Heritage Tourism",
          details: "Understanding cultural and heritage tourism",
          details2: "Managing cultural and heritage sites",
          details3: "Interpretation and presentation of cultural heritage",
          details4: "Challenges and opportunities in cultural tourism",
        },
        {
          subtitle: "Technology in Tourism and Hospitality",
          details: "Impact of technology on tourism and hospitality",
          details2: "Online booking systems and travel platforms",
          details3: "Mobile applications and digital tools for tourists",
          details4: "Future trends like VR, AI, and smart tourism",
        },
        {
          subtitle: "International Tourism",
          details: "Global tourism trends and statistics",
          details2:
            "Key international tourism organizations (UNWTO, WTTC, etc.)",
          details3: "Cross-cultural communication and international etiquette",
          details4: "Managing international tourism markets",
        },
        {
          subtitle: "Sustainable Tourism and Ecotourism",
          details: "Principles of sustainable tourism",
          details2: "Certification and eco-labeling in tourism",
          details3: "Case studies in ecotourism",
          details4: "Community-based tourism initiatives",
        },
        {
          subtitle: "Career Opportunities in Tourism and Hospitality",
          details: "Overview of career paths and opportunities",
          details2: "Skills and qualifications required",
          details3: "Professional development and networking",
          details4:
            "Challenges and rewards of a career in tourism and hospitality",
        },
        {
          subtitle: "Practical Training and Field Trips",
          details:
            "Internships and work placements in the tourism and hospitality industry",
          details2:
            "Field trips to tourism destinations and hospitality establishments",
          details3:
            "Hands-on training in tour guiding, event management, and customer service",
          details4: "Real-world projects and case studies",
        },
      ],
    },
    // IATA Travel and Tourism Training Course Syllabus - Modules and Topics
    {
      title:
        "IATA Travel and Tourism Training Course Syllabus - Modules and Topics",
      content: [
        {
          subtitle: "Module 1: Introduction to the Travel and Tourism Industry",
          weeks: [
            {
              week: 1,
              details: "Overview of the Travel and Tourism Industry",
              details2: "History and evolution of travel and tourism",
              details3: "Key industry players and their roles",
            },
            {
              week: 2,
              details: "Structure of the Travel and Tourism Industry",
              details2: "Types of tourism (leisure, business, adventure, etc.)",
              details3:
                "Components of the tourism system (transportation, accommodation, attractions, etc.)",
            },
          ],
        },
        {
          subtitle: "Module 2: IATA and Travel Industry Regulations",
          weeks: [
            {
              week: 3,
              details: "Introduction to IATA",
              details2: "History and mission of IATA",
              details3: "IATA's role in the travel industry",
            },
            {
              week: 4,
              details: "Travel Industry Regulations and Standards",
              details2: "IATA resolutions and standards",
              details3: "Government regulations and international treaties",
            },
          ],
        },
        {
          subtitle: "Module 3: Airline Reservations and Ticketing",
          weeks: [
            {
              week: 5,
              details: "Basics of Airline Reservations",
              details2: "Global Distribution Systems (GDS)",
              details3: "Passenger Name Record (PNR) creation and management",
            },
            {
              week: 6,
              details: "Fare Calculation and Ticketing",
              details2: "Types of airfares and fare rules",
              details3: "Manual and automated fare calculation",
            },
            {
              week: 7,
              details: "Electronic Ticketing (E-ticketing)",
              details2: "E-ticketing process and benefits",
              details3: "IATA’s Billing and Settlement Plan (BSP)",
            },
          ],
        },
        {
          subtitle: "Module 4: Travel Documentation and Customer Service",
          weeks: [
            {
              week: 8,
              details: "Travel Documentation",
              details2: "Passports, visas, and health requirements",
              details3: "Travel insurance and other documentation",
            },
            {
              week: 9,
              details: "Customer Service Excellence",
              details2: "Principles of effective customer service",
              details3: "Handling customer complaints and special requests",
            },
          ],
        },
        {
          subtitle: "Module 5: Travel Agency Operations",
          weeks: [
            {
              week: 10,
              details: "Establishing a Travel Agency",
              details2: "Types of travel agencies",
              details3: "Business planning and start-up requirements",
            },
            {
              week: 11,
              details: "Travel Agency Management",
              details2: "Sales and marketing strategies for travel agencies",
              details3: "Financial management and accounting",
            },
          ],
        },
        {
          subtitle: "Module 6: Tour Operations and Destination Management",
          weeks: [
            {
              week: 12,
              details: "Tour Planning and Itinerary Design",
              details2: "Types of tours and tour components",
              details3: "Designing customized itineraries",
            },
            {
              week: 13,
              details: "Destination Management",
              details2: "Understanding destination management companies (DMCs)",
              details3: "Role of DMCs in the travel industry",
            },
          ],
        },
        {
          subtitle: "Module 7: Travel Technology and Trends",
          weeks: [
            {
              week: 14,
              details: "Technology in the Travel Industry",
              details2: "Role of technology in travel management",
              details3: "Online booking tools and mobile applications",
            },
            {
              week: 15,
              details: "Future Trends in Travel and Tourism",
              details2: "Sustainable tourism practices",
              details3: "Emerging trends and innovations in the industry",
            },
          ],
        },
        {
          subtitle: "Module 8: Exam Preparation and Practical Training",
          weeks: [
            {
              week: 16,
              details: "Exam Preparation",
              details2: "Review of key concepts and practices",
              details3: "Practice exams and mock test",
            },
          ],
        },
      ],
    },
  ],

  conclusion:
    "Online teaching activities have the potential to significantly enhance tourism education by making it more accessible, flexible, and resource-rich. However, to fully realize these benefits, it is essential to address the challenges associated with online learning. By adopting innovative strategies and continuously improving online teaching practices, educators can provide high-quality tourism education that prepares students for successful careers in the industry",
};
