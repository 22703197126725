import React, { createContext, useState, useEffect } from "react";

export const ImageContext = createContext();

export const ImageProvider = ({ children }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Try to load images from localStorage first
    const storedImages = localStorage.getItem("images");
    if (storedImages) {
      // If images are found in localStorage, use them
      setImages(JSON.parse(storedImages));
    } else {
      // Otherwise, generate and fetch the images
      const totalImages = 70;
      const imagePaths = [];

      for (let i = 1; i <= totalImages; i++) {
        const imagePath = `${process.env.PUBLIC_URL}/assets/images/galleryImg/galleryImg (${i}).jpeg`;
        imagePaths.push({ src: imagePath, alt: `galleryImg (${i})` });
      }

      // Store the images in localStorage to avoid re-fetching next time
      localStorage.setItem("images", JSON.stringify(imagePaths));

      // Update the state with the image paths
      setImages(imagePaths);
    }
  }, []);

  return (
    <ImageContext.Provider value={{ images }}>{children}</ImageContext.Provider>
  );
};
