// import React, { memo, useEffect, useState } from "react";
// import "./GalleryNew.css";
// import { ImageContext } from "../../shared/services/imageContext/imageContext";

// function GalleryNew() {
//   const [popupImage, setPopupImage] = useState(null);
//   const [, setPortraits] = useState([]);
//   const [landscapes, setLandscapes] = useState([]);
//   const [visibleImagesCount, setVisibleImagesCount] = useState(12); // Initially show 12 images
//   const [imageCount, setImageCount] = useState(0); // Will hold the total count of images

//   const totalImages = 70; // Set your total number of images here

//   // Function to create image paths and classify based on aspect ratio
//   const generateImagePaths = (count) => {
//     let portraitImages = [];
//     let landscapeImages = [];

//     for (let i = 1; i <= count; i++) {
//       const imagePath = `${process.env.PUBLIC_URL}/assets/images/galleryImg/galleryImg (${i}).jpeg`;
//       const img = new Image();
//       img.src = imagePath;

//       // Wait for the image to load before calculating its aspect ratio
//       img.onload = () => {
//         const aspectRatio = img.width / img.height;
//         if (aspectRatio > 1) {
//           landscapeImages.push({ src: imagePath, alt: `galleryImg (${i})` });
//         } else {
//           portraitImages.push({ src: imagePath, alt: `galleryImg (${i})` });
//         }

//         // Update state once all images are classified
//         if (i === count) {
//           setPortraits(portraitImages);
//           setLandscapes(landscapeImages);
//           setImageCount(count); // Set the total image count
//         }
//       };
//     }
//   };

//   const { images } = useContext(ImageContext)

//   useEffect(() => {
//     // Call the generateImagePaths function when the component is mounted
//     generateImagePaths(totalImages);
//   }, []);

//   // Function to handle opening the popup with the selected image
//   const openPopup = (imageSrc) => {
//     setPopupImage(imageSrc);
//   };

//   // Function to handle closing the popup
//   const closePopup = () => {
//     setPopupImage(null);
//   };

//   // Load More Images logic
//   const loadMoreImages = () => {
//     setVisibleImagesCount(visibleImagesCount + 12); // Load 12 more images on each click
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="gallery-new-container">
//       <div className="gallery-sub-container">
//         {/* Landscape Gallery */}
//         <ul className="gallery-list">
//           {landscapes.slice(0, visibleImagesCount).map((image, index) => (
//             <li
//               key={index}
//               className="gallery-list-item"
//               onClick={() => openPopup(image.src)}
//             >
//               <img
//                 src={image.src}
//                 alt={image.alt}
//                 className="gallery-list-item-image"
//                 loading="lazy"
//               />
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Load More Button */}
//       {visibleImagesCount < imageCount && (
//         <div className="load-more-container">
//           <button className="load-more-button" onClick={loadMoreImages}>
//             Load More
//           </button>
//         </div>
//       )}

//       {/* Popup Section */}
//       {popupImage && (
//         <div className="popup-overlay" onClick={closePopup}>
//           <div className="popup">
//             <img src={popupImage} className="popup-image" />
//             <div className="close-button" onClick={closePopup}>
//               <svg
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M18 6L6 18"
//                   stroke="white"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <path
//                   d="M6 6L18 18"
//                   stroke="white"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default memo(GalleryNew);



// import React, { memo, useEffect, useState, useContext } from "react";
// import "./GalleryNew.css";
// import { ImageContext } from "../../shared/services/imageContext/imageContext";

// function GalleryNew() {
//   const [popupImage, setPopupImage] = useState(null);
//   const [landscapes, setLandscapes] = useState([]);
//   const [portraits, setPortraits] = useState([]);
//   const [visibleImagesCount, setVisibleImagesCount] = useState(12); // Initially show 12 images
//   const [imageCount, setImageCount] = useState(0); // Will hold the total count of images
//   const { images } = useContext(ImageContext); // Using the context for images

//   const totalImages = 70; // Set your total number of images here

//   // Function to create image paths and classify based on aspect ratio
//   const generateImagePaths = (images) => {
//     const portraitImages = [];
//     const landscapeImages = [];

//     // Classify images based on aspect ratio (landscape/portrait)
//     images.forEach((image, i) => {
//       const img = new Image();
//       img.src = image.src;
//       img.onload = () => {
//         const aspectRatio = img.width / img.height;
//         if (aspectRatio > 1) {
//           landscapeImages.push(image); // Landscape image
//         } else {
//           portraitImages.push(image); // Portrait image
//         }

//         // Once all images are classified, update the state
//         if (i === images.length - 1) {
//           setPortraits(portraitImages);
//           setLandscapes(landscapeImages);
//           setImageCount(images.length); // Set the total image count
//         }
//       };
//     });
//   };

//   useEffect(() => {
//     // Fetch and classify images once on mount
//     if (images && images.length > 0) {
//       generateImagePaths(images); // Assuming images have src property
//     }
//   }, [images]);

//   // Function to handle opening the popup with the selected image
//   const openPopup = (imageSrc) => {
//     setPopupImage(imageSrc);
//   };

//   // Function to handle closing the popup
//   const closePopup = () => {
//     setPopupImage(null);
//   };

//   // Load More Images logic
//   const loadMoreImages = () => {
//     setVisibleImagesCount(visibleImagesCount + 12); // Load 12 more images on each click
//   };

//   useEffect(() => {
//     window.scrollTo(0, 0);
//   }, []);

//   return (
//     <div className="gallery-new-container">
//       <div className="gallery-sub-container">
//         {/* Landscape Gallery */}
//         <ul className="gallery-list">
//           {landscapes.slice(0, visibleImagesCount).map((image, index) => (
//             <li
//               key={index}
//               className="gallery-list-item"
//               onClick={() => openPopup(image.src)}
//             >
//               <img
//                 src={image.src}
//                 alt={image.alt}
//                 className="gallery-list-item-image"
//                 loading="lazy"
//               />
//             </li>
//           ))}
//         </ul>
//       </div>

//       {/* Load More Button */}
//       {visibleImagesCount < imageCount && (
//         <div className="load-more-container">
//           <button className="load-more-button" onClick={loadMoreImages}>
//             Load More
//           </button>
//         </div>
//       )}

//       {/* Popup Section */}
//       {popupImage && (
//         <div className="popup-overlay" onClick={closePopup}>
//           <div className="popup">
//             <img src={popupImage} className="popup-image" />
//             <div className="close-button" onClick={closePopup}>
//               <svg
//                 viewBox="0 0 24 24"
//                 fill="none"
//                 xmlns="http://www.w3.org/2000/svg"
//               >
//                 <path
//                   d="M18 6L6 18"
//                   stroke="white"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//                 <path
//                   d="M6 6L18 18"
//                   stroke="white"
//                   strokeWidth="2"
//                   strokeLinecap="round"
//                   strokeLinejoin="round"
//                 />
//               </svg>
//             </div>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// }

// export default memo(GalleryNew);



import React, { memo, useContext, useEffect, useState } from "react";
import { ImageContext } from "../../shared/services/imageContext/imageContext";
import "./GalleryNew.css";

function GalleryNew() {
  const [popupImage, setPopupImage] = useState(null);
  const [landscapes, setLandscapes] = useState([]);
  const [portraits, setPortraits] = useState([]);
  const [visibleImagesCount, setVisibleImagesCount] = useState(12); // Initially show 12 images
  const [imageCount, setImageCount] = useState(0); // Will hold the total count of images
  const { images } = useContext(ImageContext); // Using the context for images

  const totalImages = 70; // Total number of images

  // Function to create image paths and classify based on aspect ratio
  const generateImagePaths = async (images) => {
    const portraitImages = [];
    const landscapeImages = [];
    
    // Create a promise for each image to load
    const imagePromises = images.map((image, i) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = image.src;
        img.onload = () => {
          const aspectRatio = img.width / img.height;
          if (aspectRatio > 1) {
            landscapeImages.push(image); // Landscape image
          } else {
            portraitImages.push(image); // Portrait image
          }
          resolve();
        };
      });
    });

    // Wait for all images to load
    await Promise.all(imagePromises);

    // Once all images are classified, update the state
    setPortraits(portraitImages);
    setLandscapes(landscapeImages);
    setImageCount(images.length); // Set the total image count
  };

  useEffect(() => {
    // Fetch and classify images once on mount
    if (images && images.length > 0) {
      generateImagePaths(images); // Assuming images have a src property
    }
  }, [images]);

  // Function to handle opening the popup with the selected image
  const openPopup = (imageSrc) => {
    setPopupImage(imageSrc);
  };

  // Function to handle closing the popup
  const closePopup = () => {
    setPopupImage(null);
  };

  // Load More Images logic
  const loadMoreImages = () => {
    setVisibleImagesCount(visibleImagesCount + 12); // Load 12 more images on each click
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top when this component is mounted
  }, []);

  return (
    <div className="gallery-new-container">
      <div className="gallery-sub-container">
        {/* Landscape Gallery */}
        <ul className="gallery-list">
          {landscapes.slice(0, visibleImagesCount).map((image, index) => (
            <li
              key={index}
              className="gallery-list-item"
              onClick={() => openPopup(image.src)}
            >
              <img
                src={image.src}
                alt={image.alt}
                className="gallery-list-item-image"
                loading="lazy"
              />
            </li>
          ))}
        </ul>
      </div>

      {/* Load More Button */}
      {visibleImagesCount < imageCount && (
        <div className="load-more-container">
          <button className="load-more-button" onClick={loadMoreImages}>
            Load More
          </button>
        </div>
      )}

      {/* Popup Section */}
      {popupImage && (
        <div className="popup-overlay" onClick={closePopup}>
          <div className="popup">
            <img src={popupImage} className="popup-image" />
            <div className="close-button" onClick={closePopup}>
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="white"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default memo(GalleryNew);
