import React, { useEffect } from "react";
import "./DigitalBusiness.css";
export default function DigitalBusiness() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tourism-container">
      <p className="section-subtitle">Digital Business</p>
      <div className="tourism-header">
        <h3 className="h2 section-title">
          Revolutionizing Tourism Through Digital Solutions
        </h3>
      </div>

      <div className="tourism-content">
        <p className="section-text w-80 text-justify">
          Digital solutions are revolutionizing and redefining the way people
          live, work, commute, and conduct business, and they are reforming and
          redefining tourism in the process. The range and use of digital
          technology differ significantly between countries, industries,
          businesses, and locations. The resultant possibilities and barriers
          form an unequal playing field, which is amplified by a developing gap
          between tech-driven and internationally linked tourism enterprises and
          conventional small and micro businesses, which are generally linked
          with low-tech business practices.
        </p>

        <h3 className="section-sub-heading">Incredible Experiences and New Revenue Streams</h3>
        <p className="section-text w-80 text-justify">
          Reflect on the incredible experiences that your destination has to
          offer prospective clients. With brilliant marketing tactics, and
          commercializing travel services, you can now create new revenue
          streams and leverage your additional traffic.
        </p>

        <h3 className="section-sub-heading">Key Benefits of Digital Solutions in Tourism</h3>
        <ul>
          <li>
            Providing an opportunity to take full advantage of the digital
            market's prospects to improve performance.
          </li>
          <li>
            Allowing exposure to new markets and commercial possibilities.
          </li>
          <li>
            Promote the potential of tourism SMEs to innovate and encourage
            tourism professionals.
          </li>
          <li>
            Improve the skill sets of tourism businesses, whose market potential
            is often underutilized owing to a lack of access to ongoing learning
            and understanding of technological advancements.
          </li>
          <li>
            By assisting with the creation of specific digital services for the
            tourism sector, we can help integrate tourism enterprises into the
            digital value stream.
          </li>
        </ul>
      </div>
    </div>
  );
}
