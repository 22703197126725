import React, { useEffect } from "react";
import "./PrivacyPolicyModal.css";

export default function PrivacyPolicyModal({ isOpen, onClose }) {
  // Always call the useEffect hook unconditionally
  useEffect(() => {
    // Function to handle click outside the modal
    const handleOutsideClick = (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        onClose();
      }
    };

    if (isOpen) {
      // Attach click event listener only when modal is open
      document.addEventListener("click", handleOutsideClick);

      // Clean up the event listener when the component unmounts or isOpen changes
      return () => {
        document.removeEventListener("click", handleOutsideClick);
      };
    }
  }, [isOpen, onClose]); // Dependency array ensures that the effect runs when isOpen or onClose changes

  // If the modal is not open, don't render the modal
  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>Privacy Policy for NAFA Tourism</h2>
        <p>
          <strong>Effective Date:</strong> 01/01/2025
        </p>

        <h3>1. Data Collection</h3>
        <p>
          Our website do <strong>not</strong> collect any personal information
          or data from users.
        </p>
        <p>
          We do not require you to provide any personal details such as your
          name, email address, or phone number to use or access the website.
        </p>

        <h3>2. Cookies and Third-Party Services</h3>
        <p>
          Although we do not collect personal data, our website may use{" "}
          <strong>cookies</strong> or similar technologies to improve your
          experience. Cookies are small files placed on your device to help us
          improve site functionality and track non-personally identifiable
          information about how you interact with our website.
        </p>
        <p>We may use third-party services, such as:</p>
        <ul>
          <li>
            <strong>Google Analytics:</strong> We use Google Analytics to
            understand how visitors interact with our site. You can read more
            about how Google Analytics handles your data{" "}
            <a
              href="https://www.google.com/analytics/learn/privacy.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              here
            </a>
            .
          </li>
          <li>
            <strong>Social Media Buttons/Widgets:</strong> These services may
            collect data related to your interaction with their features. Please
            refer to their privacy policies for more information.
          </li>
        </ul>

        <h3>3. Links to Third-Party Sites</h3>
        <p>
          Our website may contain links to third-party websites. We are not
          responsible for their privacy practices. Please read the privacy
          policies of external sites.
        </p>

        <h3>4. Security</h3>
        <p>
          Our website does not collect personal information, but we take
          precautions to ensure that the site is secure. No sensitive personal
          data is processed on our servers.
        </p>

        <h3>5. Updates to This Privacy Policy</h3>
        <p>
          We may update this Privacy Policy. Any changes will be posted on this
          page with the updated date.
        </p>

        <h3>6. Contact Us</h3>
        <p>If you have any questions, please contact us at:</p>
        <p>
          <strong>Email:</strong> shaikh@nafatourism.com
        </p>
        <p>
          <strong>Phone:</strong> +91 9619 982 990
        </p>

        <div className="modal-footer">
          <button onClick={onClose}>Close</button>
        </div>
      </div>
    </div>
  );
}
