import React, { useEffect } from "react";
import "./SocialMedia.css";
export default function SocialMedia() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="social-media-container">
      <p className="section-subtitle">Social Media</p>
      <div className="social-media-header">
        <h2 className="h2 section-title">
          Establishing the Most Effective Social Media Approach for Your Brand
        </h2>
      </div>

      <div className="social-media-content">
        <p className="section-text w-80 text-justify">
          If your company isn't yet social media savvy, now is the time to get
          started. Social media will only grow in importance as a facet of how
          our community interacts, which means it will play an increasingly
          greater role in marketing.
        </p>

        <p className="section-text w-80 text-justify">
          Tourism marketing and social media are indeed a perfect match made in
          the digital realm. Social networking sites are one of the most
          successful tools for any brand to gain new consumers, but the travel
          and tourism industry can harness it uniquely. <strong>N A F A </strong> partners with
          businesses in the travel, hospitality, and tourism organizations to
          take their social media campaigns to the next level.
        </p>

        <h3 className="section-sub-heading">Perks of Marketing Your Tourism Business on Social Media</h3>
        <ul>
          <li>Improved Engagement Incentives</li>
          <li>Boosting Conversions</li>
          <li>Demographic Retargeting</li>
          <li>Gaining New Consumers</li>
        </ul>
      </div>
    </div>
  );
}
