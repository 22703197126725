import React, { useEffect } from "react";
import { aboutUsData } from "../../shared/services/services";
import Team from "../OurTeam/OurTeam";
import "./About.scss";
import { Helmet } from "react-helmet";

const AboutUs = () => {
  const data = aboutUsData;
  const { description, belief, operations, reasons } = data;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Helmet>
        <meta name="title" content="About Us - NAFA Tourism Consultancy" />
        <meta
          name="description"
          content="At NAFA, we specialize in Training, Marketing, PR, and communications for India’s tourism and hospitality sector. Backed by a seasoned team with regional and international expertise."
        />
        <meta
          name="keywords"
          content="about NAFA, tourism training, tourism marketing, PR for tourism, hospitality sector India"
        />
        <meta
          property="og:title"
          content="About Us - NAFA Tourism Consultancy"
        />
        <meta
          property="og:description"
          content="At NAFA, we specialize in Training, Marketing, PR, and communications for India’s tourism and hospitality sector."
        />
        <meta property="og:url" content="https://yourwebsite.com/about" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="link-to-your-image.jpg" />
      </Helmet>

      {/* About Us Content Section */}
      <section className="about-section">
        <div className="container">
          <div className="description">
            <p>{description}</p>
          </div>
          <div className="belief">
            <h3 className="heading section-sub-heading">Our Belief</h3>
            <p>{belief}</p>
          </div>
          <div className="operations">
            <h3 className="heading section-sub-heading">Operations</h3>
            <p>{operations}</p>
          </div>
          <div className="reasons">
            <h3 className="heading section-sub-heading">Why Choose Us</h3>
            <ul>
              {reasons.map((reason, index) => (
                <li key={index} className="list-item-reason">
                  {reason}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <Team />
    </>
  );
};

export default AboutUs;
