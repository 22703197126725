import React, { useEffect } from "react";
import { galleryImages } from "../../shared/services/services";
import GalleryNew from "../GalleryNew/GalleryNew";
import Header from "../Header/Header";
import "./Gallery.css";
const Gallery = () => {
  // const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Header />
      {/* <Banner/> */}
      <section className="gallery" id="gallery">
        <div className="container">
          <p className="section-subtitle">Photo Gallery </p>
          <h2 className="h2 section-title">Photos From Travellers</h2>
          <p className="section-text gallery-txt">
            {/* Explore captivating moments from travelers worldwide, showcasing
              breathtaking landscapes, vibrant cultures, and unforgettable
              adventures. */}
            Discover unforgettable moments captured by our travelers across the
            globe, showcasing breathtaking destinations, unique experiences, and
            the vibrant essence of travel and hospitality
          </p>

          <ul className="gallery-list">
              {galleryImages.map((image, index) => (
                <li key={index} className="gallery-item">
                  <figure className="gallery-image">
                    <img src={image.src} alt={image.alt} />
                  </figure>
                </li>
              ))}
          </ul>
        </div>
        <hr className="pad-20" />
      </section>
      <GalleryNew />
    </>
  );
};

export default Gallery;
