import React, { useEffect } from "react";
import "./OurTeam.css";
import { teamData } from "../../shared/services/services";
export default function OurTeam() {
  const data = teamData;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <div className="ourTeamContainer">
        <h1>Our Team</h1>

        {/* Introduction and General Information */}
        <p>{data.intro}</p>

        <div className="teamInfo">
          <h2>Our Expertise</h2>
          <ul>
            {data.expertise.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>

        <div className="container-head-serve">
          <h2 className="heading-services">Industries We Serve</h2>
          <div className="card-grid-service">
            {data.sectors.map((industry, index) => (
              <div key={index} className="card-serive">
                {industry}
              </div>
            ))}
          </div>
        </div>

        <hr />

        {/* Team Member Biography */}
        <div className="bioSection">
          <h2>Team Biography</h2>
          <h3>{data.teamMember.name}</h3>

          {/* Video Section */}
          <section className="video-intro-section">
            <div className="container">
              <video
                className="intro-video"
                src="./assets/videos/introduction.mp4"
                controls
                preload="none"
                poster="./assets/images/thumbnail.png"
              >
                Your browser does not support the video tag.
              </video>
            </div>
          </section>
          <hr />
          <div className="team-bio-main">
            <div className="bioDetails">
              {data.teamMember.bio.map((item, index) => (
                <p key={index}>{item}</p>
              ))}
            </div>
            <div className="right">
              <img src="./assets/images/ProfesorImg.png" alt="Professor" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
